import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import examService from "services/ExamService";

export const createExam = createAsyncThunk('exam/createExam', async (data, { rejectWithValue }) => {
    const { domain, title, description, sequence, imgUrl } = data
	try {
		const response = await examService.createExam(domain, { title, description, sequence, imgUrl})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getDomainExams = createAsyncThunk('exam/getDomainExams', async ({domain}, { rejectWithValue }) => {
	try {
		const response = await examService.getExams(domain)
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getExamSessions = createAsyncThunk('exam/getExamSessions', async (data, { rejectWithValue }) => {
    const { examId } = data
	try {
		const response = await examService.getExamSessions({examId})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createSubject = createAsyncThunk('exam/createSubject', async (data, { rejectWithValue }) => {
    const { examId, title, description, content, sequence, key } = data
	try {
		const response = await examService.createExamSubject({ title, description, content, sequence, examId, key})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const createTopic = createAsyncThunk('exam/createTopic', async (data, { rejectWithValue }) => {
    const { examId, subjectId, title, description, content, sequence, key } = data
	try {
		const response = await examService.createExamTopic({ title, examId, subjectId, description, content, sequence, key})
		return response.data
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
    domainExams: {},
    examSessions: {},
    status: 'idle',
    error: null
}

export const examSlice = createSlice({
    name: 'exam',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
        .addCase(createExam.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(createExam.fulfilled, (state, action) => {
            state.status = 'success'
        })
        .addCase(createExam.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getDomainExams.pending, (state) => {
            state.status = 'loading'
        })
        .addCase(getDomainExams.fulfilled, (state, action) => {
            state.status = 'success'
            const key = action?.payload?.domain?.key;
            state.domainExams[key] = action.payload
        })
        .addCase(getDomainExams.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
        .addCase(getExamSessions.pending, (state) => {
            state.status = 'loading'
            state.examSessions = {}
        })
        .addCase(getExamSessions.fulfilled, (state, action) => {
            state.status = 'success'
            state.examSessions = action.payload
        })
        .addCase(getExamSessions.rejected, (state, action) => {
            state.status = 'failure'
            state.error = action.error.message
        })
    }

})

export default examSlice.reducer

export const selectAllDomainExams = state => state.exam.domainExams
export const selectExamStatus = state => state.exam.status
export const selectExamById = (state, examId) => state.exam.exams.exams.find(exam => examId === exam.id)
export const selectExamSessions = (state) => {
    let sessions = state.exam.examSessions;
    return sessions
}
